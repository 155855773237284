























import Vue from "vue";

interface AreaItem {
  alt: string;
  text: string;
  value: string;
  img: string;
}

/**
 * Emits @Input for v-model value
 */
export default Vue.extend({
  name: "AreaSelect",
  props: {
    /**
     * value is used for v-model implementation.
     * It defines if the area is international (true) or national/germany (false)
     */
    value: {
      type: Boolean,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    attachTo: {
      type: String,
      default: "",
    },
  },
  data: function () {
    let items: AreaItem[] = [];

    items.push({
      text: this.$t("areaSelect.local").toString(),
      value: this.$t("local").toString(),
      img: require(`@/assets/images/area/${this.$i18n.locale}-Flag.png`),
      // eslint-disable-next-line prettier/prettier
      alt: `${this.$t("areaSelect.local").toString()} ${this.$t("areaSelect.altFlag").toString()}`
    });

    if (this.$i18n.locale === "de") {
      items.push({
        text: this.$t("areaSelect.international").toString(),
        value: "international",
        img: require("@/assets/images/area/240px-Earth_icon_2.png"),
        // eslint-disable-next-line prettier/prettier
        alt: `${this.$t("areaSelect.international").toString()} ${this.$t("areaSelect.altFlag").toString()}`
      });
    }

    return {
      items,
      selection: "",
    };
  },
  mounted(): void {
    this.setSelection();
  },
  watch: {
    /** change selection on value change */
    value(): void {
      this.setSelection();
    },
  },
  computed: {
    isInternational(): boolean {
      return this.items.length > 1
        ? this.selection == this.items[1].value
        : this.selection == this.items[0].value;
    },
  },
  methods: {
    setSelection(): void {
      this.selection =
        this.value && this.items.length > 1
          ? this.items[1].value
          : this.items[0].value;
      this.$emit("input", this.isInternational);
    },
    onInputChange() {
      this.$emit("input", this.isInternational);
    },
  },
});
