
















import Vue from "vue";
import Radius from "@/models/radius";
import radii from "@/assets/resources/radii";

export default Vue.extend({
  name: "RadiusSelect",
  props: {
    value: {
      type: String,
    },
    isInternational: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    attachTo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      radius: {} as Radius,
      radii: [
        {
          text: this.$t("radiusSelect.allOver"),
          value: "",
        },
        {
          text: "5 km",
          value: "5km",
        },
        {
          text: "10 km",
          value: "10km",
        },
        {
          text: "25 km",
          value: "25km",
        },
        {
          text: "50 km",
          value: "50km",
        },
      ],
    };
  },
  watch: {
    /** change selection on value change */
    value(): void {
      this.setRadius();
    },
  },
  computed: {
    disabled(): boolean {
      return this.isInternational;
    },
    radiiArray(): Radius[] {
      return radii;
    },
  },
  mounted(): void {
    this.setRadius();
  },
  methods: {
    setRadius() {
      if (this.radius.value != this.value) {
        this.radius = this.getRadiusObject();
      }
    },
    onChange(inputValue) {
      if (this.value != inputValue) {
        this.$emit("input", inputValue);
      }
    },
    onEnter(): void {
      this.$emit("input", this.radius.value);
      this.$emit("enter");
    },
    /**
     * Retrieve the equivalnt Radius object from radii.json based on the value of the radius input.
     */
    getRadiusObject() {
      return (
        radii.find((radius: Radius) => radius.value == this.value) || radii[0]
      );
    },
  },
});
