
























import Vue from "vue";
import Location from "@/models/location";
import LocationService from "@/services/LocationService";

export default Vue.extend({
  name: "LocationSearchBar",
  props: {
    value: {
      type: String,
      required: true,
    },
    isInternational: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    attachTo: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      location: this.value as string | undefined,
      searchValue: "",
    };
  },
  computed: {
    shownLocations(): Location[] {
      const q = this.searchValue || this.location || "";
      return this.isInternational
        ? LocationService.findCountryByName(q)
        : LocationService.findLocationByPlzOrName(q);
    },
    hintText(): string {
      return this.isInternational
        ? `${this.$t("locationSearchBar.country")}`
        : `${this.$t("locationSearchBar.location")}`;
    },
  },
  mounted(): void {
    this.location = this.value;
  },
  watch: {
    /** change selection on value change */
    value(): void {
      this.$nextTick(() => {
        this.location = this.searchValue = this.value;
      });
    },
    isInternational(): void {
      this.searchValue = "";
      this.location = "";
    },
  },
  methods: {
    /**
     * Filter all locations based on the user input to give proposals instead of giving the full location item list.
     * The postcode and location name are taken into account.
     *
     * @param {any} item: The current location item to check if it matches the user input.
     * @param queryText
     * @param {string} itemText: The text value of the item.
     * @return {boolean}:  If the checked item should be display in the proposal list.
     */
    filterLocations(item: any, queryText: string, itemText: string): boolean {
      if (queryText) {
        const search = queryText.toLowerCase();

        const name = item.name.toLowerCase();
        let plz = "";
        let displayString = name;
        if (item.plz) {
          plz = item.plz;
          displayString = plz + " " + displayString;
        }
        return (
          name.includes(search) ||
          plz.includes(search) ||
          displayString.includes(search) ||
          itemText.toLowerCase().includes(search)
        );
      } else return true;
    },
    /**
     * This event is triggert after every new input key. It will emit an input event if the new value is empty.
     */
    locationOnKeyUp(evt): void {
      const handleDesktop =
        evt.code.startsWith("Key") ||
        evt.code === "Backspace" ||
        (evt.keyCode >= 48 && evt.keyCode <= 57) ||
        (evt.keyCode >= 96 && evt.keyCode <= 105);
      const handleMobile =
        Number.isInteger(evt.key) ||
        evt.key.match(/[a-z]/i) ||
        evt.key === "Backspace";
      if (handleDesktop || handleMobile) {
        const curValue = evt.target.value;
        const plz = curValue.match(/^\d+/);
        if (plz) {
          this.searchValue = plz[0];
        } else {
          this.searchValue = curValue;
          // emit event by clearing field
          if (!curValue) {
            this.location = "";
            this.$emit("input", this.location);
          }
        }
      }
    },
    /**
     * Clear search value on focus.
     */
    onFocus(evt): void {
      if (!evt.target.value) {
        this.searchValue = "";
      }
    },
    /**
     * Reduces the searchValue to only one word and emits an input event with the new location.
     */
    onInputChange(): void {
      this.searchValue = this.searchValue.split(" ")[0];
      this.$emit("input", this.location);
    },
    onEnter(): void {
      this.searchValue = this.location || "";
      this.$emit("enter", this.location);
    },
  },
});
